import type { FilterObject } from 'o365.pwa.modules.sw.Devex.ts';
import type { WhereExpression } from 'o365.pwa.modules.shared.dexie.WhereExpression.ts';

export interface IOfflineRecordSourceOptions extends IRecordSourceOptions {
    dataObjectId: string;
    viewName: string;
    uniqueTable: string;
    appIdOverride: string;
    databaseIdOverride: string;
    objectStoreIdOverride: string;
    filterObject: FilterObject;
    whereObject: FilterObject;
    indexedDbWhereExpression: WhereExpression;
    masterDetailObject: FilterObject;
}

export interface IRecordSourceOptions {
    dataSourceId?: string,
    viewName?: string,
    uniqueTable?: string,
    distinctRows?: boolean,
    skip?: number,
    fields?: IRecordSourceFieldType[],
    loadRecents?: boolean,
    maxRecords?: number,
    whereClause?: string,
    masterDetailString?: string,
    filterString?: string,
    optimisticLocking?: boolean,
    /**
     * For retrieve/rowcount operations this is used as definition procedure parameters  
     * For create/update operations these are item values
     */
    values?: Record<string, any>,
    searchString?: string, 
    searchFunction?: string,
    /** Build context filter query in the backend */
    expandView?: boolean;
    definitionProc?: string;
    /** Current user context id. Used together with expandView  */
    contextId?: number | null;
    /** Clear the storage before storing retrieved items */
    clearStorage?: boolean,
    /** Filter object for client side filtering (the filter expression) */
    filterObject?: any // typeof DataObject.prototype.filterObject.filterObject,
    /** Master details filter object for client side filtering */
    masterDetailObject?: any // typeof DataObject.prototype.filterObject.filterObject,
    /** Where clause filter object for client side filtering */
    whereObject?: any // typeof DataObject.prototype.filterObject.filterObject,
    /** Skip abort handler for this request */
    skipAbortCheck?: boolean;
    /** Skip request grouping for this request */
    skipGroupRequest?: boolean;
    /** Optional additional where clause used when filtering properties */
    propertiesWhereClause?: string;
    /**
     * View to which properties are actually connected. Backend will use this view to find the connected properties 
     * instead of viewName.
     */
    masterPropertyView?: string; 
    sqlStatementParameters?: Record<string, any>
    definitionProcParameters?: Record<string, any>
    timezoneName?: string
    /** Aliases used by sub selects to construct exists clauses */
    subSelectAliases?: Record<string, {
        viewName: string,
        binding: string,
        fields: Record<string, string>
    }>
    /** WIP - experimental feature */
    idCacheID?: number;
    idCacheSize?: number;
    idCacheBaseSortOrder?: IRecordSourceFieldType[];
    /** Do not set this manually. If used when id cahce is not active you will not get the full dataset. */
    idCacheSkipInsert?: boolean;
}

export interface IRecordSourceFieldType {
    name: string;
    alias?: string;
    sortOrder?: number;
    sortDirection?: 'asc' | 'desc';
    groupByOrder?: number;
    groupByAggregate?: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG';
    aggregate?: 'COUNT' | 'SUM' | 'MIN' | 'MAX' | 'AVG';
    round?: number;
}
